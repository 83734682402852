@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js");

@import '~font-awesome/css/font-awesome.css';
// @import "scss";
@import "scss/style";
@import "@ng-select/ng-select/themes/default.theme.css";
// @import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "assets/dropify/css/dropify.min.css";
@import "assets/css/line-icons.css";

@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';
// @import '@able-pro/angular-pro/dist/assets/scss/able-pro-dark.scss';

html {
  scroll-behavior: smooth;
}

// Start Left Side Css Moidifaction
// .pcoded-navbar .pcoded-inner-navbar li > a {
//   padding: 3px 10px 3px 2px;
// }
// .pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon {
//   font-size: 16px;
//   padding: 6px 7px 5px 2px;
//   width: 10px;
//   height: 25px;
// }
// .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {
//   padding: 2px 7px 2px 15px;
//   font-size: 13px;
// }
// .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu > app-nav-item > li > a {
//   padding: 5px 7px 5px 20px;
// }
// .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
//   position: unset;
// }
// .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu > a:after {
//   background: #adadad;
//   color: #fff;
//   border-radius:50px;
// }
// .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu a:after {
//   background: #000;
// }
// End Left Side Css Moidifaction


/* You can add global styles to this file, and also import other style files */

// @media (min-width: 992px){
//   .modal-lg {
//       max-width: 70% !important;
//   }
// }

// @media (min-width: 576px){
//   .modal-dialog {
//       max-width: 70% !important;
//       margin: 1.75rem auto;
//   }
// }

.modal {
  overflow: 100%;
}

.look {
  background: #f3f3f3;
}

.accordion-msg {
  background: #ffffff;
  border-bottom: 1px #c6c6c6 solid;
}

.accordion-head {
  background-color: #1e90ff;
  color: #fff;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}

/* ......................................................................... */
.printThis {
  overflow: 100%;
}

.row {
  margin-left: 1px;
  margin-right: 1px;
}

h5 {
  font-weight: bold;
}

p {
  font-size: medium;

}

.table-responsive {
  padding-left: 10px;
  padding-right: 10px;
}

.pdf-title {
  padding-top: 5px;
  padding-bottom: 2px;
  background: #427286;
  font-weight: bolder;
  font-size: 19px;

  h5 {
    color: #ffffff;
    font-size: 19px;
  }
}

.pdf-title2 {
  padding-top: 5px;
  padding-bottom: 2px;
  background: #427286;
  font-weight: bolder;
  font-size: 21px;

  h5 {
    color: #ffffff;
    font-size: 21px;
  }
}

.pdf-sub {
  background: #639cb3;
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 18px;

  h5,
  h6 {
    color: #ffffff;
    font-size: 18px;
  }
}




.pdf-sub1 {
  background: green;
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 18px;

  h5,
  h6 {
    color: #ffffff;
    font-size: 18px;
  }
}

.table-striped .row:nth-of-type(odd) div {
  overflow-x: 100%;
  // background-color: #cbdcdf;
  background-color: none;
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
}
// .table-striped .row:nth-of-type(odd) div {
//   overflow-x: 100%;
//   // background-color: #cbdcdf;
//   background-color: #ecebe3;
//   /* padding-top: 15px; */
//   /* padding-bottom: 15px; */
// }

.table-striped .row:nth-of-type(even) div {
  overflow-x: 100%;
  background: #ffffff;
  margin-bottom: 0px;
}

.table-wrp {
  font-size: 17px;
  font-weight: normal;
}

.table-wrp2 {
  font-size: 16px;
  font-weight: normal;
}

.table-wrp3 {
  font-size: 18px;
  font-weight: 500;
}

.info-wrp {
  font-size: 16px;
  // text-transform: capitalize;
  // font-weight: bold;
  /* padding-left: 5px; */
  color: green;
}

.info-wrp1 {
  font-size: 16px;
  color: #000;
}

.td-wrp {
  font-size: 20px;
  // text-transform: capitalize;
  font-weight: bold;
  /* padding-left: 5px; */
  color: green;
}

textarea {
  /* box-sizing: padding-box; */
  // overflow:hidden;
  /* demo only: */
  color: green;
  font-weight: 500px;
  padding: 10px;
  width: 80%;
  font-size: 17px;
  margin-top: 50px;
  display: block;
  border-radius: 10px;
  border: 6px solid #556677;
}

/* ......................................................................................................... */

@media screen {
  #printSection {
    display: none;
  }
  #printThis {
    display: block;

    h3 {
      position: absolute;
      page-break-before: always;
      page-break-after: always;
      bottom: 0;
      right: 0;
    }

    h3::before {
      position: relative;
      bottom: -20px;
      counter-increment: section;
      content: counter(section);
    }
  }
}

@media print {
  @page {
    size: A4;
    // size: 210mm 297mm;
    margin: 2%;
    // margin: 0.5cm;
    // padding:0.25cm;
    .row {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-before: always;
      page-break-after: always;
    }
  }
  .row {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    // page-break-after: always;
  }
  .list {
    display: table-row !important;
    -webkit-print-color-adjust: exact !important;
  }

  .list > div {
    display: table-cell !important;
    -webkit-print-color-adjust: exact !important;
  }
  body * {
    visibility: hidden;
    background: none !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  body.background-blue {
    background: #fff !important;
  }
  #printSection,
  #printSection * {
    visibility: visible;
    /* margin: 0; */
  }
  #printSection {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    min-height: 550px;
  }

  .doNotPrint {
    display: none !important;
    font-family: Arial, Helvetica, sans-serif !important;
  }
  .modal,
  .modal * {
    overflow: 100% !important;
  }
  .table-responsive {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .pdf-title {
    padding-top: 5px !important;
    padding-bottom: 2px !important;
    background: #ecf0f5 !important;
    font-weight: bolder !important;
    border: #e2e5e8 1px solid;
    font-size: 20px !important;
    // color: #ffffff !important;
    h5 {
      color: #000000 !important;
      font-size: 20px !important;
    }

    -webkit-print-color-adjust: exact;
  }
  .pdf-title2 {
    padding-top: 5px !important;
    padding-bottom: 2px !important;
    background: #ecf0f5 !important;
    font-weight: bolder !important;
    border: #e2e5e8 1px solid;
    font-size: 22px !important;
    // color: #ffffff !important;
    h5 {
      color: #000000 !important;
      font-size: 22px !important;
    }

    -webkit-print-color-adjust: exact;
  }
  .pdf-sub {
    background: #ecf0f5 !important;
    font-size: 20px !important;
    font-weight: bolder !important;
    // color: #ffffff !important;
    h5,
    h6 {
      color: #000000 !important;
      font-size: 18px !important;
    }

    border: #e2e5e8 1px solid;
    text-transform: capitalize !important;
    -webkit-print-color-adjust: exact;
  }
  .pdf-sub1 {
    background: #e4e9ef !important;
    font-size: 20px !important;
    font-weight: bolder !important;

    h5 {
      color: green !important;
      font-size: 18px !important;
    }

    text-transform: capitalize !important;
    -webkit-print-color-adjust: exact;
  }
  .table-striped .row:nth-of-type(odd) div {
    overflow-x: 100% !important;
    background-color: #f9fbff !important;
    // border-bottom: #e2e5e8 1px solid;
    /* padding-top: 15px !important; */
    /* padding-bottom: 15px !important; */
    -webkit-print-color-adjust: exact;
  }
  .table-striped .row:nth-of-type(even) div {
    overflow-x: 100% !important;
    background: #ffffff !important;
    // border-bottom: #e2e5e8 1px solid;
    margin-bottom: 0px;
    -webkit-print-color-adjust: exact;
  }
  .table-wrp {
    font-size: 17px !important;
    font-weight: normal !important;
  }
  .table-wrp2 {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  .info-wrp {
    // text-transform: capitalize !important;
    font-size: 16px !important;
    // font-weight: bold !important;
    /* padding-left: 5px !important; */
    color: green;
  }
  .td-wrp {
    // text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: bold !important;
    /* padding-left: 5px !important; */
    color: green;
  }
  .tb-sign{
   font-size: 20px !important;
  }
}

.watermark {

  height: 100%;


}

@media print {
  .watermark:before {
    content: attr(data-watermark);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;

    color: #0d745e;
    font-size: 100px;
    font-weight: 600;
    display: grid;
    justify-content: center;
    align-content: center;
    opacity: 0.2;
    transform: rotate(-45deg);
    user-select: none; /* Non-prefixed version for chorme, opera and*/
    -ms-user-select: none; /* Internet Explorer, Edge */
    -moz-user-select: none; /* Firefox */
    -khtml-user-select: none; /* Konqueror HTML */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
  }
}

// .watermark:before{
// 	content: attr(data-watermark);
// 	position: fixed;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	z-index: 999;

// 	color: #0d745e;
// 	font-size: 120px;
// 	font-weight: 700px;
// 	display: grid;
// 	justify-content: center;
// 	align-content: center;
// 	opacity: 0.2;
// 	transform: rotate(-45deg);
//   user-select: none; /* Non-prefixed version for chorme, opera and*/
//   -ms-user-select: none; /* Internet Explorer, Edge */
//   -moz-user-select: none; /* Firefox */
//   -khtml-user-select: none; /* Konqueror HTML */
//   -webkit-touch-callout: none; /* iOS Safari */
//   -webkit-user-select: none; /* Safari */
//   }

// .....................................................  MY STYLES  ................................................................................//
.body {
  font-size: 1.2rem;
  font-weight: 800;
}

// .btn-success {
// color: #fff;
// background-color: #02ad09;
// border-color: #4e8015;
// }
// .btn-info {
// color: #fff;
// background-color: #009688;
// border-color: #046f65;
// }
// .btn-warning {
//   color: #fff;
//   background-color: #ffba57;
//   border-color: #eaa036;
// }
// .btn-danger {
//   color: #fff;
//   background-color: #ef0000;
//   border-color: #9f2121;
// }
// .close{
//   margin-top: -21px;
//   margin-right: -20px;
// }
// .btn-primary {
//   color: #fff;
//   background-color: #0547d9;
//   border-color: #1b3d89;
// }
// .btn-secondary {
//   color: #fff;
//   background-color: #6c757d;
//   border-color: #585c5f;
// }
// .btn-imagine {
//   color: #fff;
//   background-color: #673ab7;
//   border-color: #701188;
// }
.form-group .form-control {
  padding-left: 10px;
  padding-right: 10px;
  font-size: larger;
  color: #000000;
  font-weight: 600;
}

.modal-body {
  background: #ffffff;
}

.pcoded-navbar .pcoded-inner-navbar li {
  background: #0000000d;
  margin: 5px;
}

.pcoded-navbar .pcoded-inner-navbar li :active {
  background: #00000017;
}

// @media only screen and (max-width: 991px){
//   .pcoded-navbar {
//     margin-left: -10px;
//     position: absolute;
//     height: calc(100% - 50px);
//   }
// }

// .............................................................................................  MY STYLES  ............................................................................................//

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  border: none !important;
}

// input[type="text"][disabled] {
//    color: #861b1b;
//    background: #e0cdcd;
// }
input[disabled] {
  color: #861b1b;
  background: #e0cdcd;
}

ng-select {
  font-weight: bolder;
}

// @import "@ng-select/ng-select/themes/default.theme.css";

textarea.form-control {
  margin: 0;
}

.p_info {
  font-size: 0.85em;
  // padding: 0.94em;
  font-family: Arial, Helvetica, sans-serif;
}

.p_info > h5 {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.p_info > table {
  // background-color: red;
  width: 100%;
  margin-bottom: 1.85em;
  // border: 1px solid black;
}

.p_info > table > tr > th {
  text-align: left;
  border: 1px solid black;
}

.p_info > table > tr > td {
  padding-left: 0.4em;
  border: 1px solid black;
  height: 35px;
}

.p_info > table > tr.borderless > td {
  border: none;
  // background-color: green;
}

.p_info > table > tr > td.fit-content {
  width: auto;
}

// @import "@ng-select/ng-select/themes/default.theme.css";

.form-group {
  position: relative;
  margin-bottom: 0.5rem;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
}

.form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
  background: no-repeat center bottom, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  transition: background 0s ease-out;
}

.form-control:focus {
  border-bottom-color: transparent;
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
  box-shadow: none;
  background-image: linear-gradient(
      to top,
      #4680ff 2px,
      rgba(70, 128, 255, 0) 2px
  ),
  linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px);
}

.form-group {
  position: relative;
}

.form-group .form-control {
  padding-left: 0;
  padding-right: 0;
}

.form-group .floating-label {
  position: absolute;
  top: 11px;
  left: 0;
  font-size: 0.95rem;
  z-index: 1;
  cursor: text;
  transition: all 0.3s ease;
}

.form-group .floating-label + .form-control {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}

.form-group.fill .floating-label {
  top: -10px;
  font-size: 0.75rem;
  color: #4680ff;
}

.label {
  font-size: 0.625em;
  background: #607D8B;
  color: #dedede;
  font-weight: bold;
  height: 25px;
  text-transform: uppercase;
}

.light-navy-blue {
  background-color: #34495e;
  color: #fff;
}

.btn.btn-navy-blue,
button:hover.btn.btn-navy-blue {
  background-color: #34495e;
  color: #fff;
}

button:hover.btn.btn-outline-navy-blue {
  background-color: #34495e;
  color: #fff;
}

.btn.btn-outline-navy-blue {
  background-color: transparent;
  color: #34495e;
}

input.ng-pristine.advice {
  color: red;
}

input.ng-dirty.advice {
  color: green
}

// .btn .btn-navy-blue{
//    background-color: #34495e;
//   color:#fff;
// }

input.table-input {
  box-sizing: border-box;
  padding: 10px 5px;
}

// Datatable
table.dataTable td.dataTables_empty {
  display: none;
}


.no-data-available {
  text-align: center;
}

.badge-finalised {
  background: #1f75fe;
  border-color: #ffecd0;
  color: #fff;


}

.badge-draft {
  background: #daa520;
  border-color: #ffecd0;
  color: #fff;

}


.ql-editor {
  font-size: 21px;
}

.ql-editor p {
  font-size: 21px;
}

.ql-editor u {
  font-size: 21px;
}

.ql-snow .ql-editor h4 {
  font-size: 21px;
}


.spin-main {
  position: relative;
}

.spin-icon {
  position: absolute;
  right: 60px;
  top: 20px;
}

.cursor {
  cursor: pointer;
}


.list {
  position: relative !important;
  display: block !important;
  padding: 0.75rem 1rem !important;
  cursor: pointer !important;
  margin-bottom: 5px !important;
  background-color: rgba(245, 245, 245, 0.7) !important;
  border-radius: 10px !important;
}

.list a {
  background-color: rgba(245, 245, 245, 0.7) !important;
}


/* authentication wrapper */
.a-wrapper {
  min-width: 100%;
  min-height: 100%;
  position: relative;
  top: 0;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
}

.main-panel {
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow: auto;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

@media (min-width: 768px) {
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (min-width: 992px) {
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
}

.text-blue {
  color: #030d7a;
}

@page {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

/* Custom Styles - Tonnise  */
.btn-round {
  border-radius: 1rem;
}

.toPrint {
  display: none;
}

.bg-billed {
  background-color: #4680ff;
  color: white !important;

  h5, h4, h3 {
    color: white !important;
    font-weight: bold;
  }
}

.bg-paid {
  background-color: #9ccc65;
  color: white !important;

  h5, h4, h3 {
    color: white !important;
    font-weight: bold;
  }
}

.bg-arrears {
  background-color: #ff5252;
  color: white !important;

  h5, h4, h3 {
    color: white !important;
    font-weight: bold;
  }
}

.bg-portal {
  background-color: #e0a800;
  color: white !important;

  h5, h4, h3 {
    color: white !important;
    font-weight: bold;
  }
}

.border-top-radius {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

@media print {
  #contentToPrint {
    padding-left: 30px;
    padding-right: 30px;
  }

  .toPrint {
    display: block;
  }
}

.footer-gutter {
  margin-top: -60px
}

@media print {
  .footera {
    // position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #ccc;
    padding: 0;
    margin: 0;
    // padding: 10px;
  }
}
.badge-info-2{
  // background-color: #071067;
  background-color: #7611e5;
  color: #ffffff;
}